import * as React from "react";
import Header from "../../Components/Header/Header";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";
import CompanyInfo from "../../Components/CompanyInfo/CompanyInfo";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";

const CompanyInfoPage = () => {
  return (
    <div>
      <Seo locale="it" page="company info" />
      <Header lang="it" />
      <HamburgerMenu />
      <CompanyInfo lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </div>
  );
};

export default CompanyInfoPage;
